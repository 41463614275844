.flex {
  display: flex;
}

.flex-row {
  @extend .flex;
  flex-direction: row;
}

.flex-column {
  @extend .flex;
  flex-direction: column;
}

.flex-initial {
  flex: initial;
}

.flex-none {
  flex: none;
}

@for $i from 1 through 12 {
  .flex-#{$i} {
    flex: $i;
  }
}

.flex.align-items-center {
  align-items: center;
}

.flex.align-items-stretch {
  align-items: stretch;
}

.flex.justify-content-center {
  justify-content: center;
}

.flex.justify-content-start {
  justify-content: flex-start;
}

.flex.justify-content-end {
  justify-content: flex-end;
}

.full-height {
  height: 100%;
}

/* https://github.com/developscript/Loader-made-with-css */

.loader {
  position: relative;
  display: block;
  margin: 0 auto;
  font-size: 40px;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0)
}

.loader.loader-default {
  width: 30px;
  height: 30px;
  background-color: #a3afb7;
  border-radius: 100%;
  -webkit-animation: loader-default 1s infinite ease-in-out;
  -o-animation: loader-default 1s infinite ease-in-out;
  animation: loader-default 1s infinite ease-in-out
}

.loader.loader-default.small {
  width: 30px;
  height: 30px;
}

.loader.loader-grill {
  width: .25em;
  height: .5em;
  background: #a3afb7;
  -webkit-animation: default-grill 1s infinite ease-in-out;
  -o-animation: default-grill 1s infinite ease-in-out;
  animation: default-grill 1s infinite ease-in-out;
  -webkit-animation-delay: -.16s;
  -o-animation-delay: -.16s;
  animation-delay: -.16s
}

.loader.loader-grill:after,
.loader.loader-grill:before {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: #a3afb7;
  -webkit-animation: default-grill 1s infinite ease-in-out;
  -o-animation: default-grill 1s infinite ease-in-out;
  animation: default-grill 1s infinite ease-in-out
}

.loader.loader-grill:before {
  left: -.375em;
  -webkit-animation-delay: -.32s;
  -o-animation-delay: -.32s;
  animation-delay: -.32s
}

.loader.loader-grill:after {
  left: .375em
}

.loader.loader-circle {
  width: 1em;
  height: 1em;
  border-top: .125em solid rgba(163, 175, 183, .5);
  border-right: .125em solid rgba(163, 175, 183, .5);
  border-bottom: .125em solid rgba(163, 175, 183, .5);
  border-left: .125em solid #a3afb7;
  border-radius: 50%;
  -webkit-animation: loader-circle 1.1s infinite linear;
  -o-animation: loader-circle 1.1s infinite linear;
  animation: loader-circle 1.1s infinite linear
}

.loader.loader-round-circle {
  width: 1em;
  height: 1em;
  font-size: 10px;
  border-radius: 50%;
  -webkit-animation: loader-round-circle 1.3s infinite linear;
  -o-animation: loader-round-circle 1.3s infinite linear;
  animation: loader-round-circle 1.3s infinite linear
}

.loader.loader-tadpole {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  -webkit-animation: loader-tadpole 1.7s infinite ease;
  -o-animation: loader-tadpole 1.7s infinite ease;
  animation: loader-tadpole 1.7s infinite ease
}

.loader.loader-ellipsis {
  width: .625em;
  height: .625em;
  border-radius: 50%;
  -webkit-animation: loader-ellipsis 1.8s infinite ease-in-out;
  -o-animation: loader-ellipsis 1.8s infinite ease-in-out;
  animation: loader-ellipsis 1.8s infinite ease-in-out;
  -webkit-animation-delay: -.16s;
  -o-animation-delay: -.16s;
  animation-delay: -.16s;
  -webkit-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both
}

.loader.loader-ellipsis:after,
.loader.loader-ellipsis:before {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  border-radius: 50%;
  -webkit-animation: loader-ellipsis 1.8s infinite ease-in-out;
  -o-animation: loader-ellipsis 1.8s infinite ease-in-out;
  animation: loader-ellipsis 1.8s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both
}

.loader.loader-ellipsis:before {
  left: -.875em;
  -webkit-animation-delay: -.32s;
  -o-animation-delay: -.32s;
  animation-delay: -.32s
}

.loader.loader-ellipsis:after {
  left: .875em
}

.loader.loader-bounce {
  width: 1.5em;
  height: 1.5em
}

.loader.loader-bounce:after,
.loader.loader-bounce:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: #a3afb7;
  border-radius: 50%;
  opacity: .6;
  -webkit-animation: loader-bounce 2s infinite ease-in-out;
  -o-animation: loader-bounce 2s infinite ease-in-out;
  animation: loader-bounce 2s infinite ease-in-out
}

.loader.loader-bounce:after {
  -webkit-animation-delay: -1s;
  -o-animation-delay: -1s;
  animation-delay: -1s
}

/* ====== FRAMES ====== */
@-webkit-keyframes loader-default {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@-o-keyframes loader-default {
  0% {
    -webkit-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0)
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes loader-default {
  0% {
    -webkit-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0)
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
  }
}

@-webkit-keyframes default-grill {
  0%, 100%, 80% {
    height: 1em;
    -webkit-box-shadow: 0 0 #a3afb7;
    box-shadow: 0 0 #a3afb7
  }
  40% {
    height: 1.2em;
    -webkit-box-shadow: 0 -.25em #a3afb7;
    box-shadow: 0 -.25em #a3afb7
  }
}

@-o-keyframes default-grill {
  0%, 100%, 80% {
    height: 1em;
    box-shadow: 0 0 #a3afb7
  }
  40% {
    height: 1.2em;
    box-shadow: 0 -.25em #a3afb7
  }
}

@keyframes default-grill {
  0%, 100%, 80% {
    height: 1em;
    -webkit-box-shadow: 0 0 #a3afb7;
    box-shadow: 0 0 #a3afb7
  }
  40% {
    height: 1.2em;
    -webkit-box-shadow: 0 -.25em #a3afb7;
    box-shadow: 0 -.25em #a3afb7
  }
}

@-webkit-keyframes loader-circle {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-o-keyframes loader-circle {
  0% {
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0)
  }
  100% {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes loader-circle {
  0% {
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0)
  }
  100% {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-webkit-keyframes loader-round-circle {
  0%, 100% {
    -webkit-box-shadow: 0 -3em 0 .2em #a3afb7, 2em -2em 0 0 #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 0 #a3afb7;
    box-shadow: 0 -3em 0 .2em #a3afb7, 2em -2em 0 0 #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 0 #a3afb7
  }
  12.5% {
    -webkit-box-shadow: 0 -3em 0 0 #a3afb7, 2em -2em 0 .2em #a3afb7, 3em 0 0 0 #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7;
    box-shadow: 0 -3em 0 0 #a3afb7, 2em -2em 0 .2em #a3afb7, 3em 0 0 0 #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7
  }
  25% {
    -webkit-box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 0 #a3afb7, 3em 0 0 .2em #a3afb7, 2em 2em 0 0 #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7;
    box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 0 #a3afb7, 3em 0 0 .2em #a3afb7, 2em 2em 0 0 #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7
  }
  37.5% {
    -webkit-box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 0 #a3afb7, 2em 2em 0 .2em #a3afb7, 0 3em 0 0 #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7;
    box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 0 #a3afb7, 2em 2em 0 .2em #a3afb7, 0 3em 0 0 #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7
  }
  50% {
    -webkit-box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 0 #a3afb7, 0 3em 0 .2em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7;
    box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 0 #a3afb7, 0 3em 0 .2em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7
  }
  62.5% {
    -webkit-box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 0 #a3afb7, -2em 2em 0 .2em #a3afb7, -3em 0 0 0 #a3afb7, -2em -2em 0 -.5em #a3afb7;
    box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 0 #a3afb7, -2em 2em 0 .2em #a3afb7, -3em 0 0 0 #a3afb7, -2em -2em 0 -.5em #a3afb7
  }
  75% {
    -webkit-box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 .2em #a3afb7, -2em -2em 0 0 #a3afb7;
    box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 .2em #a3afb7, -2em -2em 0 0 #a3afb7
  }
  87.5% {
    -webkit-box-shadow: 0 -3em 0 0 #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 0 #a3afb7, -2em -2em 0 .2em #a3afb7;
    box-shadow: 0 -3em 0 0 #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 0 #a3afb7, -2em -2em 0 .2em #a3afb7
  }
}

@-o-keyframes loader-round-circle {
  0%, 100% {
    box-shadow: 0 -3em 0 .2em #a3afb7, 2em -2em 0 0 #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 0 #a3afb7
  }
  12.5% {
    box-shadow: 0 -3em 0 0 #a3afb7, 2em -2em 0 .2em #a3afb7, 3em 0 0 0 #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7
  }
  25% {
    box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 0 #a3afb7, 3em 0 0 .2em #a3afb7, 2em 2em 0 0 #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7
  }
  37.5% {
    box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 0 #a3afb7, 2em 2em 0 .2em #a3afb7, 0 3em 0 0 #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7
  }
  50% {
    box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 0 #a3afb7, 0 3em 0 .2em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7
  }
  62.5% {
    box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 0 #a3afb7, -2em 2em 0 .2em #a3afb7, -3em 0 0 0 #a3afb7, -2em -2em 0 -.5em #a3afb7
  }
  75% {
    box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 .2em #a3afb7, -2em -2em 0 0 #a3afb7
  }
  87.5% {
    box-shadow: 0 -3em 0 0 #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 0 #a3afb7, -2em -2em 0 .2em #a3afb7
  }
}

@keyframes loader-round-circle {
  0%, 100% {
    -webkit-box-shadow: 0 -3em 0 .2em #a3afb7, 2em -2em 0 0 #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 0 #a3afb7;
    box-shadow: 0 -3em 0 .2em #a3afb7, 2em -2em 0 0 #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 0 #a3afb7
  }
  12.5% {
    -webkit-box-shadow: 0 -3em 0 0 #a3afb7, 2em -2em 0 .2em #a3afb7, 3em 0 0 0 #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7;
    box-shadow: 0 -3em 0 0 #a3afb7, 2em -2em 0 .2em #a3afb7, 3em 0 0 0 #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7
  }
  25% {
    -webkit-box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 0 #a3afb7, 3em 0 0 .2em #a3afb7, 2em 2em 0 0 #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7;
    box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 0 #a3afb7, 3em 0 0 .2em #a3afb7, 2em 2em 0 0 #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7
  }
  37.5% {
    -webkit-box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 0 #a3afb7, 2em 2em 0 .2em #a3afb7, 0 3em 0 0 #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7;
    box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 0 #a3afb7, 2em 2em 0 .2em #a3afb7, 0 3em 0 0 #a3afb7, -2em 2em 0 -.5em #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7
  }
  50% {
    -webkit-box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 0 #a3afb7, 0 3em 0 .2em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7;
    box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 0 #a3afb7, 0 3em 0 .2em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 -.5em #a3afb7, -2em -2em 0 -.5em #a3afb7
  }
  62.5% {
    -webkit-box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 0 #a3afb7, -2em 2em 0 .2em #a3afb7, -3em 0 0 0 #a3afb7, -2em -2em 0 -.5em #a3afb7;
    box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 0 #a3afb7, -2em 2em 0 .2em #a3afb7, -3em 0 0 0 #a3afb7, -2em -2em 0 -.5em #a3afb7
  }
  75% {
    -webkit-box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 .2em #a3afb7, -2em -2em 0 0 #a3afb7;
    box-shadow: 0 -3em 0 -.5em #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 .2em #a3afb7, -2em -2em 0 0 #a3afb7
  }
  87.5% {
    -webkit-box-shadow: 0 -3em 0 0 #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 0 #a3afb7, -2em -2em 0 .2em #a3afb7;
    box-shadow: 0 -3em 0 0 #a3afb7, 2em -2em 0 -.5em #a3afb7, 3em 0 0 -.5em #a3afb7, 2em 2em 0 -.5em #a3afb7, 0 3em 0 -.5em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 0 #a3afb7, -2em -2em 0 .2em #a3afb7
  }
}

@-webkit-keyframes loader-tadpole {
  0% {
    -webkit-box-shadow: 0 -.83em 0 -.4em #a3afb7, 0 -.83em 0 -.42em #a3afb7, 0 -.83em 0 -.44em #a3afb7, 0 -.83em 0 -.46em #a3afb7, 0 -.83em 0 -.477em #a3afb7;
    box-shadow: 0 -.83em 0 -.4em #a3afb7, 0 -.83em 0 -.42em #a3afb7, 0 -.83em 0 -.44em #a3afb7, 0 -.83em 0 -.46em #a3afb7, 0 -.83em 0 -.477em #a3afb7;
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  5%,
  95% {
    -webkit-box-shadow: 0 -.83em 0 -.4em #a3afb7, 0 -.83em 0 -.42em #a3afb7, 0 -.83em 0 -.44em #a3afb7, 0 -.83em 0 -.46em #a3afb7, 0 -.83em 0 -.477em #a3afb7;
    box-shadow: 0 -.83em 0 -.4em #a3afb7, 0 -.83em 0 -.42em #a3afb7, 0 -.83em 0 -.44em #a3afb7, 0 -.83em 0 -.46em #a3afb7, 0 -.83em 0 -.477em #a3afb7
  }
  10%,
  59% {
    -webkit-box-shadow: 0 -.83em 0 -.4em #a3afb7, -.087em -.825em 0 -.42em #a3afb7, -.173em -.812em 0 -.44em #a3afb7, -.256em -.789em 0 -.46em #a3afb7, -.297em -.775em 0 -.477em #a3afb7;
    box-shadow: 0 -.83em 0 -.4em #a3afb7, -.087em -.825em 0 -.42em #a3afb7, -.173em -.812em 0 -.44em #a3afb7, -.256em -.789em 0 -.46em #a3afb7, -.297em -.775em 0 -.477em #a3afb7
  }
  20% {
    -webkit-box-shadow: 0 -.83em 0 -.4em #a3afb7, -.338em -.758em 0 -.42em #a3afb7, -.555em -.617em 0 -.44em #a3afb7, -.671em -.488em 0 -.46em #a3afb7, -.749em -.34em 0 -.477em #a3afb7;
    box-shadow: 0 -.83em 0 -.4em #a3afb7, -.338em -.758em 0 -.42em #a3afb7, -.555em -.617em 0 -.44em #a3afb7, -.671em -.488em 0 -.46em #a3afb7, -.749em -.34em 0 -.477em #a3afb7
  }
  38% {
    -webkit-box-shadow: 0 -.83em 0 -.4em #a3afb7, -.377em -.74em 0 -.42em #a3afb7, -.645em -.522em 0 -.44em #a3afb7, -.775em -.297em 0 -.46em #a3afb7, -.82em -.09em 0 -.477em #a3afb7;
    box-shadow: 0 -.83em 0 -.4em #a3afb7, -.377em -.74em 0 -.42em #a3afb7, -.645em -.522em 0 -.44em #a3afb7, -.775em -.297em 0 -.46em #a3afb7, -.82em -.09em 0 -.477em #a3afb7
  }
  100% {
    -webkit-box-shadow: 0 -.83em 0 -.4em #a3afb7, 0 -.83em 0 -.42em #a3afb7, 0 -.83em 0 -.44em #a3afb7, 0 -.83em 0 -.46em #a3afb7, 0 -.83em 0 -.477em #a3afb7;
    box-shadow: 0 -.83em 0 -.4em #a3afb7, 0 -.83em 0 -.42em #a3afb7, 0 -.83em 0 -.44em #a3afb7, 0 -.83em 0 -.46em #a3afb7, 0 -.83em 0 -.477em #a3afb7;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-o-keyframes loader-tadpole {
  0% {
    box-shadow: 0 -.83em 0 -.4em #a3afb7, 0 -.83em 0 -.42em #a3afb7, 0 -.83em 0 -.44em #a3afb7, 0 -.83em 0 -.46em #a3afb7, 0 -.83em 0 -.477em #a3afb7;
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0)
  }
  5%,
  95% {
    box-shadow: 0 -.83em 0 -.4em #a3afb7, 0 -.83em 0 -.42em #a3afb7, 0 -.83em 0 -.44em #a3afb7, 0 -.83em 0 -.46em #a3afb7, 0 -.83em 0 -.477em #a3afb7
  }
  10%,
  59% {
    box-shadow: 0 -.83em 0 -.4em #a3afb7, -.087em -.825em 0 -.42em #a3afb7, -.173em -.812em 0 -.44em #a3afb7, -.256em -.789em 0 -.46em #a3afb7, -.297em -.775em 0 -.477em #a3afb7
  }
  20% {
    box-shadow: 0 -.83em 0 -.4em #a3afb7, -.338em -.758em 0 -.42em #a3afb7, -.555em -.617em 0 -.44em #a3afb7, -.671em -.488em 0 -.46em #a3afb7, -.749em -.34em 0 -.477em #a3afb7
  }
  38% {
    box-shadow: 0 -.83em 0 -.4em #a3afb7, -.377em -.74em 0 -.42em #a3afb7, -.645em -.522em 0 -.44em #a3afb7, -.775em -.297em 0 -.46em #a3afb7, -.82em -.09em 0 -.477em #a3afb7
  }
  100% {
    box-shadow: 0 -.83em 0 -.4em #a3afb7, 0 -.83em 0 -.42em #a3afb7, 0 -.83em 0 -.44em #a3afb7, 0 -.83em 0 -.46em #a3afb7, 0 -.83em 0 -.477em #a3afb7;
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes loader-tadpole {
  0% {
    -webkit-box-shadow: 0 -.83em 0 -.4em #a3afb7, 0 -.83em 0 -.42em #a3afb7, 0 -.83em 0 -.44em #a3afb7, 0 -.83em 0 -.46em #a3afb7, 0 -.83em 0 -.477em #a3afb7;
    box-shadow: 0 -.83em 0 -.4em #a3afb7, 0 -.83em 0 -.42em #a3afb7, 0 -.83em 0 -.44em #a3afb7, 0 -.83em 0 -.46em #a3afb7, 0 -.83em 0 -.477em #a3afb7;
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0)
  }
  5%,
  95% {
    -webkit-box-shadow: 0 -.83em 0 -.4em #a3afb7, 0 -.83em 0 -.42em #a3afb7, 0 -.83em 0 -.44em #a3afb7, 0 -.83em 0 -.46em #a3afb7, 0 -.83em 0 -.477em #a3afb7;
    box-shadow: 0 -.83em 0 -.4em #a3afb7, 0 -.83em 0 -.42em #a3afb7, 0 -.83em 0 -.44em #a3afb7, 0 -.83em 0 -.46em #a3afb7, 0 -.83em 0 -.477em #a3afb7
  }
  10%,
  59% {
    -webkit-box-shadow: 0 -.83em 0 -.4em #a3afb7, -.087em -.825em 0 -.42em #a3afb7, -.173em -.812em 0 -.44em #a3afb7, -.256em -.789em 0 -.46em #a3afb7, -.297em -.775em 0 -.477em #a3afb7;
    box-shadow: 0 -.83em 0 -.4em #a3afb7, -.087em -.825em 0 -.42em #a3afb7, -.173em -.812em 0 -.44em #a3afb7, -.256em -.789em 0 -.46em #a3afb7, -.297em -.775em 0 -.477em #a3afb7
  }
  20% {
    -webkit-box-shadow: 0 -.83em 0 -.4em #a3afb7, -.338em -.758em 0 -.42em #a3afb7, -.555em -.617em 0 -.44em #a3afb7, -.671em -.488em 0 -.46em #a3afb7, -.749em -.34em 0 -.477em #a3afb7;
    box-shadow: 0 -.83em 0 -.4em #a3afb7, -.338em -.758em 0 -.42em #a3afb7, -.555em -.617em 0 -.44em #a3afb7, -.671em -.488em 0 -.46em #a3afb7, -.749em -.34em 0 -.477em #a3afb7
  }
  38% {
    -webkit-box-shadow: 0 -.83em 0 -.4em #a3afb7, -.377em -.74em 0 -.42em #a3afb7, -.645em -.522em 0 -.44em #a3afb7, -.775em -.297em 0 -.46em #a3afb7, -.82em -.09em 0 -.477em #a3afb7;
    box-shadow: 0 -.83em 0 -.4em #a3afb7, -.377em -.74em 0 -.42em #a3afb7, -.645em -.522em 0 -.44em #a3afb7, -.775em -.297em 0 -.46em #a3afb7, -.82em -.09em 0 -.477em #a3afb7
  }
  100% {
    -webkit-box-shadow: 0 -.83em 0 -.4em #a3afb7, 0 -.83em 0 -.42em #a3afb7, 0 -.83em 0 -.44em #a3afb7, 0 -.83em 0 -.46em #a3afb7, 0 -.83em 0 -.477em #a3afb7;
    box-shadow: 0 -.83em 0 -.4em #a3afb7, 0 -.83em 0 -.42em #a3afb7, 0 -.83em 0 -.44em #a3afb7, 0 -.83em 0 -.46em #a3afb7, 0 -.83em 0 -.477em #a3afb7;
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@-webkit-keyframes loader-ellipsis {
  0%, 100%, 80% {
    -webkit-box-shadow: 0 .625em 0 -.325em #a3afb7;
    box-shadow: 0 .625em 0 -.325em #a3afb7
  }
  40% {
    -webkit-box-shadow: 0 .625em 0 0 #a3afb7;
    box-shadow: 0 .625em 0 0 #a3afb7
  }
}

@-o-keyframes loader-ellipsis {
  0%, 100%, 80% {
    box-shadow: 0 .625em 0 -.325em #a3afb7
  }
  40% {
    box-shadow: 0 .625em 0 0 #a3afb7
  }
}

@keyframes loader-ellipsis {
  0%, 100%, 80% {
    -webkit-box-shadow: 0 .625em 0 -.325em #a3afb7;
    box-shadow: 0 .625em 0 -.325em #a3afb7
  }
  40% {
    -webkit-box-shadow: 0 .625em 0 0 #a3afb7;
    box-shadow: 0 .625em 0 0 #a3afb7
  }
}

@-webkit-keyframes loader-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@-o-keyframes loader-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0)
  }
  50% {
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes loader-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0)
  }
  50% {
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
  }
}
